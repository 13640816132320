import React, { useContext } from "react";
import classes from "./GiftSets.module.css";
import img1 from "../../../../assets/segarImg.png";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import AuthContext from "../../../Common/auth-context";
import { useDispatch } from "react-redux";
import { addSelectedBook, addTocart } from "../../../Common/redux/productSlice";
import { useNavigate } from "react-router";



const GiftSets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);
  const dataa = authCtx.allCategories?.filter(item => item.mostpopular);

  return (
    <>
      <div className={classes.bigContainer}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h1 style={{margin:'.2em 0'}}>Gift Sets</h1>
            <div className={classes.border} />
          </div>
        </div>

        <div className={classes.cardsContainer}>
          <Swiper
            spaceBetween={20}
            effect={"fade"}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            style={{
              padding: "0 1em",
              width:"100%"
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              651: {
                slidesPerView: 3,
              },
              951: {
                slidesPerView: 4,
              },
            }}
          >
            {dataa?.slice(0,7).map((props) => {
              return (
                <SwiperSlide key={props.id} className={classes.swiperslide}>
                  <div className={classes.card_container}onClick={(event)=>{event.stopPropagation();dispatch(addSelectedBook({...props, quantity: 1}));navigate(`/products-details/${props.id}`)}}>
                    <div className={classes.card_img} style={{position:"relative"}}>
                     {props?.outOfStock && <div className={classes.out_of_stock}>
                        <p>OUT OF STOCK</p>
                      </div>}
                      <img
                          src={props.images[0]}
                          alt=""
                          className={classes.img}
                        />
                      {!props?.outOfStock && <div className={classes.addContainer} onClick={(event)=>event.stopPropagation()&dispatch(addTocart({...props, quantity: 1}))}>
                      <LocalMallOutlinedIcon style={{marginBottom:'-.2em'}}/> Add To Bag
                      </div>}
                    </div>
                    <div className={classes.card_content}>
                      <h3 style={{width:'100%',fontWeight:'800',fontSize:'calc(0.8rem + 0.3vw)'}}>{props.name}</h3>
                      <p style={{width:'100%',fontWeight:'700',margin:'0'}}>$ {props.price}</p>
                    </div>
                  </div>
                  
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default GiftSets;
